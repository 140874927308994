<template>
  <div class="travel">
    <van-nav-bar
      :title="$t('Menu.report')"
      @click-right="onClickRight"
      :placeholder="true"
      fixed
      :border="false"
      z-index="999"
    >
      <!-- <template #right>
        <van-icon name="records" color="#fff" size="18" />
      </template> -->
    </van-nav-bar>
    <!-- <van-row class="travel-header-row">
      <van-col span="19">
        <van-cell-group :border="false" class="travel-header">
          <van-cell :label="allbanlance">
            <template #title>
              <div class="travel-header-title">
                <span>{{ $t("Menu.totalBalance") + "($)" }}</span>
                <van-icon
                  v-if="eyesShow"
                  name="eye-o"
                  color="#fff"
                  size="18"
                  @click="showEyes"
                />
                <van-icon
                  v-else
                  name="closed-eye"
                  color="#fff"
                  size="18"
                  @click="showEyes"
                />
              </div>
            </template>
            <template #default>
              <div>{{ $t("Menu.totalDeposit") }}</div>
              <div class="van-cell__label">{{ alldeposit }}</div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-col>
      <van-col span="5">
        <van-cell
          :title="$t('Menu.totalWithdrawal')"
          :label="allwithdrawl"
          class="travel-header-right"
        />
      </van-col>
    </van-row> -->
    <div class="travel-content">
      <van-grid :column-num="4" :border="false" class="travel-grid">
        <van-grid-item
          :icon="report_center.pc"
          :text="$t('Menu.closeReport')"
          to="/report/ordershistory"
        />
        <van-grid-item
          :icon="report_center.yj"
          :text="$t('Menu.performance')"
          to="/report/business"
        />
        <van-grid-item
          :icon="report_center.cc"
          :text="$t('Menu.positionReport')"
          to="/report/position"
        />
        <van-grid-item
          :icon="report_center.xy"
          :text="$t('Menu.creditReport')"
          to="/report/credit"
        />
        <van-grid-item
          :icon="report_center.yjj"
          :text="$t('Menu.commissionReport')"
          to="/report/commission"
        />
        <van-grid-item
          :icon="report_center.yj_tj"
          :text="$t('Menu.commissionTotal')"
          to="/report/bonus"
        />
        <van-grid-item
          :icon="report_center.hz"
          :text="$t('Menu.summaryReport')"
          to="/report/summary"
        />
        <!-- <van-grid-item :icon="icon.more" :text="$t('app.more')" /> -->
      </van-grid>
      <!-- <van-row class="travel-service">
        <van-col span="12">
          <van-cell
            :title="$t('Menu.fundsHistory')"
            :label="$t('alllogins')"
            to="/funds/history"
            center
          >
            <template #default>
              <div>
                <van-icon :name="icon.travel_ls" size="30" />
              </div>
            </template>
          </van-cell>
        </van-col>
        <van-col span="12">
          <van-cell
            :title="$t('mysignal')"
            :label="$t('alllogins')"
            center
            to="/signal/mysignal"
          >
            <template #default>
              <div>
                <van-icon :name="icon.signal" size="35" />
              </div>
            </template>
          </van-cell>
        </van-col>
      </van-row> -->
      <van-row class="travel-welfare" gutter="8">
        <van-col span="12">
          <van-swipe :autoplay="3000" class="my-swipe">
            <van-swipe-item v-for="(item, index) in swipe_img" :key="index">
              <van-image width="100%" height="244px" fit="cover" :src="item" />
            </van-swipe-item>
          </van-swipe>
        </van-col>
        <van-col span="12">
          <van-row>
            <van-col span="24" class="travel-welfare-top">
              <van-cell-group
                :border="false"
                class="travel-welfare-group"
                @click="$router.push('/home_account/change_pwd')"
              >
                <van-cell :title="$t('changePassword')" :border="false" />
                <van-cell
                  :title="$t('ChangePwd.mainPassword')"
                  :label="$t('app.security')"
                  :border="false"
                  class="travel-welfare-two"
                />
                <van-cell
                  :title="$t('ChangePwd.changeCrmPwd')"
                  class="travel-welfare-three"
                />
              </van-cell-group>
            </van-col>
            <van-col span="24">
              <van-cell-group
                class="travel-welfare-group"
                :border="false"
                @click="$router.push('/member/leverage')"
              >
                <van-cell :title="$t('Menu.changeLeverage')" :border="false" />
                <van-cell
                  :title="$t('Leverage.leverageNew')"
                  :label="$t('app.switchAccount')"
                  :border="false"
                  class="travel-welfare-two"
                />
                <van-cell
                  :title="$t('Leverage.pleaseChooseLeverageNew')"
                  class="travel-welfare-three"
                  center
                >
                  <!-- <template #default>
                    <van-icon :name="icon.suo" size="30" class="travel-icon" />
                  </template> -->
                </van-cell>
              </van-cell-group>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <div class="van-safe-area-bottom"></div>
    <!-- <div style="padding-top: 10px">
      <van-divider style="display: none" content-position="right">{{
        $t("Menu.report")
      }}</van-divider>
      <van-grid
        :column-num="2"
        square
        :gutter="10"
        icon-size="62"
        style="padding-bottom: 100px"
      >
        <van-grid-item
          :icon="icon.closeReport"
          :text="$t('Menu.closeReport')"
          to="/report/ordershistory"
          style="font-weight: bolder"
        />
        <van-grid-item
          :icon="icon.depositReport"
          :text="$t('Menu.depositReport')"
          to="/report/business"
          style="font-weight: bolder"
        />
        <van-grid-item
          :icon="icon.stockmarket"
          :text="$t('Menu.positionReport')"
          to="/report/position"
          style="font-weight: bolder"
        />
        <van-grid-item
          :icon="icon.creditcard"
          :text="$t('Menu.creditReport')"
          to="/report/credit"
          style="font-weight: bolder"
        />
        <van-grid-item
          :style="{ display: userinfo.haveIbLogin == true ? 'block' : 'none' }"
          :icon="icon.commissionreport"
          :text="$t('Menu.commissionReport')"
          to="/report/commission"
          style="font-weight: bolder"
        />
        <van-grid-item
          :style="{ display: userinfo.haveIbLogin == true ? 'block' : 'none' }"
          :icon="icon.commission"
          :text="$t('Menu.commissionTotal')"
          to="/report/bonus"
          style="font-weight: bolder"
        />
        <van-grid-item
          :icon="icon.piechart"
          :text="$t('Menu.summaryReport')"
          to="/report/summary"
          style="font-weight: bolder"
        />
      </van-grid>
    </div> -->
  </div>
</template>

<script>
import { getmemberdata } from "@/api";
export default {
  name: "Travel",
  data() {
    return {
      userinfo: this.$store.state.userinfo,
     
      swipe_img: [
        require("@/assets/image/crm/travel_pc.jpeg"),
        require("@/assets/image/crm/travel_yj.jpeg"),
        // require("@/assets/image/crm/travel_cc.jpeg"),
        require("@/assets/image/crm/travel_xy.jpeg"),
        require("@/assets/image/crm/travel_yj2.jpeg"),
        // require("@/assets/image/crm/travel_tj.jpeg"),
        require("@/assets/image/crm/travel_hz.jpeg"),
      ],
      allbanlance: 0.0,
      alldeposit: 0.0,
      allwithdrawl: 0.0,
      eyesShow: true,
      report_center: {
        pc: require("@/assets/svg/pc.svg"),
        cc: require("@/assets/image/crm/positions.png"),
        yj: require("@/assets/svg/yj_report.svg"),
        xy: require("@/assets/svg/xy_report.svg"),
        yjj: require("@/assets/svg/yjj_report.svg"),
        yj_tj: require("@/assets/svg/yongjintongji.svg"),
        hz: require("@/assets/svg/hz_report.svg"),
      },
    };
  },
  mounted() {
    // console.log(this.userinfo.haveIbLogin);
    this.getMoney();
  },
  methods: {
    triggerFile: function (event) {
      this.params.file = event.target.files[0]; // (利用console.log输出看file文件对象)
      // do something...
    },
    upload() {
      this.$http.upload("/test/upload", this.params);
    },
    async getMoney() {
      let res = await getmemberdata({});
      // this.eyesShow ? this.allbanlance = res.msg.allbanlance : '****';
      // this.eyesShow ? this.alldeposit = res.msg.alldeposit : '****';
      // this.eyesShow ? this.allwithdrawl = res.msg.allwithdrawl : '**';
      this.allbanlance = res.msg.allbanlance.toFixed(2);
      this.alldeposit = res.msg.alldeposit.toFixed(2);
      this.allwithdrawl = res.msg.allwithdrawl.toFixed(2);
    },
    showEyes() {
      this.eyesShow = !this.eyesShow;
      if (this.eyesShow) {
        this.getMoney();
      } else {
        this.allbanlance = "****";
        this.alldeposit = "****";
        this.allwithdrawl = "**";
      }
    },
    onClickLeft() {},
    onClickRight() {},
  },
};
</script>

<style scoped>
.travel {
  background-color: #f7f8fa;
}
.travel-content {
  padding: 0 10px;
}
.van-nav-bar__placeholder {
  height: 66px;
}
.travel >>> .van-nav-bar {
  background-color: #13b5b1;
  padding: 20px 0 0 0;
}
.travel >>> .van-nav-bar__title {
  color: #fff;
}
.travel >>> .van-grid-item__content {
  background-color: transparent;
}
.travel-service {
  padding: 10px 0;
  background-color: #fff;
  border-radius: 8px;
}
.travel-service .van-cell {
  padding: 0 16px;
}
.travel-service .van-col:nth-child(1) {
  border-right: 1px solid #f7f8fa;
}
.travel-welfare {
  border-radius: 8px;
  margin-top: 8px;
  overflow: hidden;
}
.my-swipe {
  border-radius: 8px;
  overflow: hidden;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.travel-welfare-group {
  border-radius: 8px;
  overflow: hidden;
}
.travel-welfare .van-cell {
  padding: 4px 12px;
}
.travel-welfare-top {
  margin-bottom: 8px;
}
.travel-header-row {
  background-color: #13b5b1;
  color: #f7f8fa;
  padding: 12px 0;
}
.travel-header-row .van-cell {
  background-color: #13b5b1;
}
.travel-header-row .van-cell {
  color: #ebedf0;
  font-size: 10px;
  padding: 0 14px;
}
.travel-header-row >>> .van-cell__value {
  color: #ebedf0;
}
.travel-header-right {
  border-left: 1px solid #c8c9cc;
}
.travel-header-row >>> .van-cell__label {
  color: #fff;
  font-size: 18px;
}
.travel-header-title {
  display: flex;
  align-items: center;
}
.travel-header-title .van-icon {
  margin-left: 8px;
}
.travel-welfare-two {
  font-size: 13px;
  color: #969799;
}
.travel-welfare-two >>> .van-cell__label {
  color: #13b513;
  font-weight: 700;
}
.travel-welfare-three {
  font-size: 12px;
  color: #c8c9cc;
}
.van-grid >>> .van-grid-item__text {
  white-space: nowrap;
}
.van-grid .van-grid-item {
  width: 25%;
}
</style>
